import React from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';

import './MovieOverlay.scss';

const MovieOverlay = (props) => {
  const { t } = useTranslation();
  let { bottomText, middleText, topText, onClick } = props;
  let maxFontSize = 7;
  let minFontSize = 2;
  const screenWidth = window.outerWidth;
  if (screenWidth < 1366) {
    maxFontSize = 5;
    minFontSize = 1.4
  }
  const getFontSizeBasedOnTextLength = (text = "", max, min) => {
    const result = (max * 10) / (text?.length / 1.25);
    if (result < min) return min;
    if (result > max) return max;
    return result;
  };
  const middleTextSize = getFontSizeBasedOnTextLength(middleText, maxFontSize, minFontSize);
  return (
    <div className="MovieOverlay" onClick={onClick}>
      <div className="TextWrapper">
        {topText ? <div className="TopText">{topText}</div> : null}
        <div className="MovieOverlay-additional">
          {middleText ? <div className="MiddleText" style={{ fontSize: `${middleTextSize}rem` }}>{middleText}</div> : null}
          {bottomText ? <div className="BottomText">{bottomText}</div> : null}
          <div className="WatchNowBtn">{t('MovieOverlay.watchNow')}</div>
        </div>
      </div>
    </div>
  );
};

MovieOverlay.propTypes = {
  bottomText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  onClick: PropTypes.func,
  topText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default MovieOverlay;

