export const SecondaryNavigation = Object.freeze({
    Az: "a-z",
    Duration: "duration",
    Newest: "newest",
    MostWatched: "most_watched",
    Scenes: 'scenes',
    Relevance: 'relevance',
    topMovies: 'top_movies'
});

export const navigationOptions = Object.freeze({
    newest: "newest",
    mostWatched: "most watched",
    duration: "duration",
    az: "a-z",
    movies: 'movies',
    scenes: 'scenes',
    photos: 'photos',
    relevance: 'relevance',
});

export const getSortKeyParam = (sort) => {
    let values = Object.entries(SecondaryNavigation).filter(([key, value]) => value === sort);
    if (values.length) {
        return values[0][0];
    }
    return null;
};

export const urlSearchParamKeys = {
    content: 'content',
    sort: 'sort',
    page: 'page',
    mostWatched: 'MostWatched',
    aZ: 'Az'
};

export const urlSearchParamsPlaceholder = 'content=Scenes&sort=Newest&page=1';

export const upperCaseFirstLetter = (val) => {
    if (!val) return '';
    if (typeof val === 'number') return val;
    if (SecondaryNavigation.MostWatched === val) val = urlSearchParamKeys.mostWatched;
    if (SecondaryNavigation.Az === val) val = urlSearchParamKeys.aZ;
    return val.charAt(0).toUpperCase() + val.slice(1);
};

export const getSortByOptions = () => {
    return (
        [{
            name: navigationOptions.newest,
            value: SecondaryNavigation.Newest
        }, {
            name: navigationOptions.mostWatched,
            value: SecondaryNavigation.MostWatched
        }, {
            name: navigationOptions.duration,
            value: SecondaryNavigation.Duration
        }, {
            name: navigationOptions.az,
            value: SecondaryNavigation.Az
        }]
    )
};

export const getShowMeOptions = (photos = false) => {
    const options = [
        { name: navigationOptions.movies, value: navigationOptions.movies },
        { name: navigationOptions.scenes, value: navigationOptions.scenes }
    ];

    if (photos) {
        options.push({ name: navigationOptions.photos, value: navigationOptions.photos })
    }
    return options;
};