import { useEffect, useState } from "react";
import { UsersController } from "../controllers/users-controller/users.controller";
import { isUserLoggedIn } from "../services/token-service/token.service";

function useUserStateChange() {
    const [logged, setLogged] = useState(isUserLoggedIn());
    const onUserStateChange = () => {
        setLogged(isUserLoggedIn());
    };
    useEffect(() => {
        const change = UsersController.userStateChange.subscribe(onUserStateChange);
        return () => {
            change.unsubscribe();
        };
    }, []);

    return [logged];
};

export default useUserStateChange;