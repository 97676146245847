import React, { useEffect, useState, useRef } from 'react';
import { navigate } from '@reach/router';

import ImageLoaderComponent from '../ImageLoaderComponent/ImageLoaderComponent';
import TruncateText from '../TruncateText/TruncateText';

import {
    getDirectorDetailsPageRoute,
    getStarDetailsRoute,
    getStudioDetailsRoute,
    getThemeDetailsRoute
} from '../../services/navigation/navigation.service.routes';
import { getPosterImage } from '../../services/images-service/images.service';

import './MovieSceneDetails.scss';

const MovieSceneDetails = (props) => {
    const {
        studios = [],
        directors = [],
        categories = [],
        stars = [],
        description,
        images = [],
        url
    } = props.data || {};

    const [cropText, setCropText] = useState(false);
    const [expandText, setExpandText] = useState(false);

    const descriptionRef = useRef(null);
    const descriptionLineHeight = '28px'; // important to crop text

    useEffect(() => {
        if (descriptionRef.current) {
            const container = descriptionRef.current || {};
            const containerHeight = container.offsetHeight;
            const lineHeight = parseInt(container.style?.lineHeight);
            const lines = Math.floor(containerHeight / lineHeight);

            if (lines > 8) {
                setCropText(true);
            }
        }

        return () => {
            setCropText(false);
            setExpandText(false);
        }
    }, [description]);

    const renderDescription = () => {
        return (
            <div>
                <div className='MSD-Summary'>Summary</div>
                <span
                    className={`MSD-Desciption ${(cropText && !expandText) ? 'MSD-Clamp' : ''}`}
                    style={{ lineHeight: descriptionLineHeight }}
                    ref={descriptionRef}
                >
                    {description}
                </span>

                {cropText && (
                    <span className='MSD-ReadMoreLess cursor-pointer' onClick={() => setExpandText(!expandText)}>
                        {expandText ? ' Read less' : 'Read more...'}
                    </span>
                )}
            </div>
        )
    };

    const renderInfoRow = (heading, data = [], routeFn) => {
        if (data.length === 0) return null;

        return (
            <div className='MSD-SectionRow'>
                <div className='MSD-SectionTitle'>{heading}</div>
                <TruncateText data={data} route={routeFn} />
            </div>
        )
    };

    const renderPoster = () => {
        let view = null;

        if (images.length !== 0) {
            const frontPoster = getPosterImage(images);
            const backPoster = getPosterImage(images, false);

            view = (
                <ImageLoaderComponent
                    alt={frontPoster.alt || ''}
                    url={frontPoster.url}
                    backImageUrl={backPoster.url}
                    altBackImage={backPoster.alt || ''}
                    className='MSD-Poster cursor-pointer'
                    onClick={() => navigate(url)}
                    width='210'
                    height='300'
                />
            );
        }

        return view;
    };

    return (
        <div className='MovieSceneDetails'>
            <div className='MSD-Info'>
                <div>
                    {renderPoster()}
                </div>
                <div>
                    {renderInfoRow('stars', stars, getStarDetailsRoute)}
                    {renderInfoRow('director', directors, getDirectorDetailsPageRoute)}
                    {renderInfoRow('studio', studios, getStudioDetailsRoute)}
                    {renderInfoRow('themes', categories, getThemeDetailsRoute)}
                </div>
            </div>
            {description && renderDescription()}
        </div>
    )
};

MovieSceneDetails.displayName = 'MovieSceneDetails';

export default MovieSceneDetails;