import React, { useEffect, useState, useRef } from 'react';
import { Link } from '@reach/router';

import './TruncateText.scss';

const TruncateText = (props) => {
    const { data = [], route } = props;

    const [showToggleIcon, setShowToggleIcon] = useState(false);
    const [toggle, setToggle] = useState(false);

    const containerRef = useRef(null);
    const innerContainerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const container = containerRef.current;
            const innerContainer = innerContainerRef.current;

            if (container.getBoundingClientRect().height > 30) {
                innerContainer.style.maxWidth = (container.getBoundingClientRect().width - 30) + 'px';
                setShowToggleIcon(true);
            }
        }
    }, [data.length]);

    return (
        <div className='TruncateText' ref={containerRef}>
            <div ref={innerContainerRef} className={showToggleIcon && !toggle ? 'TT-NoWrap' : ''}>
                {data.map(item => {
                    const { id, name } = item.tag ? item.tag : item;
                    return <Link to={route(id, name)} key={`${id}-${name}`} className='TT-Item'>{name}</Link>;
                })}
            </div>

            {showToggleIcon && (
                <span className={`TT-ToggleBtn ${toggle ? 'CloseBefore' : ''}`} onClick={() => setToggle(!toggle)}></span>
            )}
        </div>
    )
};

TruncateText.displayName = 'TruncateText';

export default TruncateText;