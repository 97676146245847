import React from 'react';
import { Link } from "@reach/router";
import PropTypes from 'prop-types';

import { getStarDetailsRoute } from "../../services/navigation/navigation.service.routes";

import SceneImageHoverPlayer from '../SceneImageHoverPlayer/SceneImageHoverPlayer';
import { navigationOptions } from '../../constants/navigation';

import './MovieBoxCoverStillDisplay.scss';

const MovieBoxCoverStillDisplay = (props) => {
  const {
    alt,
    boxCoverUrl,
    heroStillUrl,
    startTimeSeconds,
    endTimeSeconds,
    sampleVideoUrl,
    duration,
    movieId,
    sceneId,
    sceneNavigationRoute = '',
    movieNavigationRoute = '',
    renderStillOverlayFn,
    renderIndicatorFn,
    showImageOverlay,
    hidePoster,
    stars = [],
    title = '',
    onClick,
    viewType = navigationOptions.scenes
  } = props;

  const isScene = viewType === navigationOptions.scenes;

  const renderInfos = () => {
    let view = null;

    if (sceneNavigationRoute) {
      view = <Link className="Title" to={sceneNavigationRoute}>{title}</Link>;
    } else {
      view = <div className="Title">{title}</div>;
    }

    return (
      <div className="MovieBoxCoverStillDisplayInfos">
        {title && view}
        {isScene && stars.length !== 0 && (
          <div className='StarsContainer'>
            {stars.map((item, index) => {
              const { id, name } = item || {};
              return (
                <Link
                  to={getStarDetailsRoute(id, name)}
                  key={item.id}
                  className='Stars'
                >
                  {item.name}{index !== stars.length - 1 && ', '}
                </Link>
              )
            })}
          </div>
        )}
      </div>
    );
  };

  const renderImageOverlay = () => {
    if (!showImageOverlay) return null;
    return <div className='BoxCoverStillDisplayImageOverlay' />;
  };

  const renderPlayerPreview = () => {
    return (
      <div className={`PlayerContainer ${hidePoster ? 'FullWidth' : ''}`}>
        {renderStillOverlayFn && renderStillOverlayFn()}
        {renderIndicatorFn && renderIndicatorFn()}
        <Link to={sceneNavigationRoute}>
          <SceneImageHoverPlayer
            heroStillUrl={heroStillUrl}
            alt={alt}
            movieId={movieId}
            sceneId={sceneId}
            sampleVideoUrl={sampleVideoUrl}
            startTimeSeconds={startTimeSeconds}
            endTimeSeconds={endTimeSeconds}
          />
        </Link>
        <div className="Duration">{duration}</div>
      </div>
    );
  };

  const renderPoster = () => {
    if (hidePoster && isScene) return null;

    let view = (
      <>
        {!isScene && renderStillOverlayFn && renderStillOverlayFn()}
        <Link to={movieNavigationRoute}>
          <img
            src={boxCoverUrl}
            alt={alt || 'BoxCover'}
            width="175"
            height="240"
            className='PosterImage'
          />
        </Link>
      </>
    )

    if (!boxCoverUrl) view = <div className='MissingImage'>Missing image...</div>;

    return <div className='PosterContainer'>{view}</div>;
  };

  const renderViewType = () => {
    if (isScene) {
      return (
        <>
          {renderPoster()}
          {renderPlayerPreview()}
          {renderImageOverlay()}
        </>
      )
    } else {
      return renderPoster();
    }
  };

  return (
    <div onClick={onClick}
      className={`${isScene ? 'SceneView' : 'MovieView'}`}>
      <div className='MovieBoxCoverStillDisplay'>
        {renderViewType()}
      </div>
      {renderInfos()}
    </div>
  );
};

MovieBoxCoverStillDisplay.propTypes = {
  alt: PropTypes.string.isRequired,
  boxCoverUrl: PropTypes.string,
  duration: PropTypes.string,
  endTimeSeconds: PropTypes.number,
  heroStillUrl: PropTypes.string,
  movieId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  sceneId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func,
  sceneNavigationRoute: PropTypes.string,
  movieNavigationRoute: PropTypes.string,
  renderStillOverlayFn: PropTypes.func,
  renderIndicatorFn: PropTypes.func,
  showImageOverlay: PropTypes.bool,
  hidePoster: PropTypes.bool,
  sampleVideoUrl: PropTypes.string,
  startTimeSeconds: PropTypes.number,
  stars: PropTypes.array,
  title: PropTypes.string,
  viewType: PropTypes.string,
};

MovieBoxCoverStillDisplay.displayName = 'MovieBoxCoverStillDisplay';

export default MovieBoxCoverStillDisplay;
