import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { ReactComponent as DownloadSvgIcon } from '../../../images/downloadIcon.svg';
import { isWeb } from '../../../services/util-service/util.service';
import "./MovieDownloads.scss";

const MovieDownloads = (props) => {
    const { downloadLoading = false, downloadOptions = [], onClick } = props;
    const [optionsOpen, setOptionsOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!isWeb()) {
                try {
                    if (!event.target.className.includes('DownloadIcon')) {
                        setOptionsOpen(false);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    const bytesForHuman = (bytes) => {
        if (!bytes) return;

        let units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
        let i = 0;

        for (i; bytes > 1024; i++) {
            bytes /= 1024;
        }

        return bytes.toFixed(1) + ' ' + units[i];
    };

    const handleMobileToggle = () => {
        if (!isWeb()) setOptionsOpen(prev => !prev);
    };

    const renderView = () => {
        if (downloadLoading) return <i className='fa fa-spinner fa-spin DownloadSpinner'></i>;

        return (
            <>
                <span className="DownloadIcon cursor-pointer" onClick={handleMobileToggle} title="Download">
                    <DownloadSvgIcon />
                </span>
                <table className="DownloadsOptionsTable" {...(!isWeb() && { style: { display: optionsOpen ? 'block' : 'none' } })}>
                    <thead>
                        <tr>
                            <th>Quality</th>
                            <th>Size</th>
                            <th>Format</th>
                        </tr>
                    </thead>

                    <tbody>
                        {downloadOptions.map(item => {
                            const { tagId, bitrate = 320, filesize, format } = item;
                            return (
                                <tr
                                    key={tagId}
                                    className="DownloadsOptionsTableItem cursor-pointer"
                                    onClick={() => onClick(bitrate)}
                                >
                                    <td>{bitrate}p</td>
                                    <td>{bytesForHuman(filesize)}</td>
                                    <td>{format}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </>
        )
    };

    return <div className="Downloads">{renderView()}</div>;
};

MovieDownloads.propTypes = {
    downloadLoading: PropTypes.bool,
    downloadOptions: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func
};

export default MovieDownloads;