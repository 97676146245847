import { useState, useEffect, useRef } from 'react';

import { SecondaryNavigation, navigationOptions } from '../constants/navigation';
import { handleHistory } from './useRouteChange';
import { getScenes } from '../services/scenes-service/scenes.service';
import { getMovies } from '../services/movies/movies.service';
import { routes } from '../services/navigation/navigation.service.routes';

const useMoviesScenesData = (props) => {
    const {
        location = {}, // reach router
        isVisible = true, // is element in viewport
        hookParams = {}, // API params for /feed
        hookDetailsRequest, // API request
        hookDetailsParams = {}, // API params for details /details
        videosType = navigationOptions.scenes, // movies/scenes
        videosSortType = SecondaryNavigation.Newest
    } = props;

    const subsetSort = location.pathname === routes.justAdded ?
        SecondaryNavigation.Newest : SecondaryNavigation.MostWatched; // two pages use this component
    const isJustAddedMostWatchedPage = location.pathname === routes.justAdded || location.pathname === routes.mostWatched;

    const initialState = {
        videos: [],
        pagination: { page: location.state?.page || 1, last_page: 1 },
        sort: isJustAddedMostWatchedPage ? subsetSort : location.state?.sort || videosSortType,
        type: location.state?.type || videosType,
        loading: true,
        error: false
    };

    const [state, setState] = useState(initialState);
    const { pagination: { page } = {}, sort, type } = state;
    const pushToHistoryRef = useRef(false);

    // for details page (StudioDetailsPage.js, ThemeDetailsPage.js, etc...)      
    useEffect(() => {
        const { route, ...detailsParams } = hookDetailsParams || {};

        if (typeof hookDetailsRequest !== 'function' || !route) return;

        hookDetailsRequest(route, { ...detailsParams })
            .then((resp = {}) => {
                const detailsData = resp.data?.data;
                if (detailsData) {
                    detailsData.banner = detailsData.banner ? { data: [detailsData.banner] } : {}

                    setState(prevState => ({
                        ...prevState,
                        ...detailsData
                    }));
                }
            })
            .catch(err => console.log(err));

        // eslint-disable-next-line 
    }, [hookDetailsRequest, location.pathname]); // location.pathname for search

    useEffect(() => {
        if (!isVisible) return;
        let isMounted = true; // to ommit infinite loop

        // on browser back button click, return to the previous page/filter
        handleHistory(
            { page, sort, type },
            (page, sort, type) => {
                if (!isMounted) return;

                setState(prevState => ({
                    ...prevState,
                    pagination: { ...prevState.pagination, page },
                    sort,
                    type
                }));
            },
            pushToHistoryRef
        );

        setState(prevState => ({ ...prevState, videos: [], loading: true }));

        const requestType = type === navigationOptions.scenes ? getScenes : getMovies;

        const params = {
            page,
            sort_by: isJustAddedMostWatchedPage ? subsetSort : sort,
            ...hookParams // params from swagger
        };

        if (isJustAddedMostWatchedPage) {
            params.subset_sort_by = sort;
            params.subset_limit = type === navigationOptions.scenes ? 360 : 480;
            params.per_page = type === navigationOptions.scenes ? 18 : 24;
        }

        requestType({ ...params })
            .then((resp = {}) => {
                const { pagination = {}, scenes = [], movies = [] } = resp.data?.data || {};
                const { current_page, last_page } = pagination || {};
                const videos = scenes.length !== 0 ? scenes : movies;

                if (!isMounted) return;

                setState(prevState => ({
                    ...prevState,
                    videos: Array.isArray(videos) ? videos : [],
                    pagination: { page: current_page, last_page }
                }));
            })
            .catch((err) => {
                console.log(err);
                setState(prevState => ({ ...prevState, error: true }));
            })
            .finally(() => setState(prevState => ({ ...prevState, loading: false })));

        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line  
    }, [page, sort, type, isVisible, isJustAddedMostWatchedPage, subsetSort, location.pathname]); // location.pathname for search

    const onPageChange = (val) => {
        setState(prevState => ({
            ...prevState,
            videos: [],
            pagination: { ...prevState.pagination, page: val }
        }));
    };

    const onTypeChange = (val, callback) => {
        if (val === navigationOptions.photos) {
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            setState(prevState => ({
                ...prevState,
                videos: [],
                type: val,
                sort: sort,
                pagination: { page: 1, last_page: 1 }
            }));
        }
    };

    const onSortByClick = (val) => {
        setState(prevState => ({
            ...prevState,
            videos: [],
            sort: val,
            pagination: { page: 1, last_page: 1 }
        }))
    };

    return { onPageChange, onTypeChange, onSortByClick, ...state };
};

export default useMoviesScenesData;