import { Subject } from 'rxjs';
import { LoadingController } from "../loading-controller/loading.controller";
import { authLogout } from "../../services/auth-service/auth.service";
import { removeUserLocalData } from "../../services/users-service/users.service";
import { cleanupToken } from "../../services/token-service/token.service";
import { logError } from "../../services/log/log.service";
import { navigate } from '@reach/router';
import { routes } from '../../services/navigation/navigation.service.routes';
import { getURLPath } from "../../services/navigation/navigation.service";
import { onEventGtag } from '../../hook/useGtag';
import { AGE_WARNING_OVERLAY } from '../../components/AgeWarningOverlay/AgeWarningOverlay';

class UsersCtrl {
  static instance;
  userStateChange = new Subject();

  static getInstance() {
    if (UsersCtrl.instance == null) {
      UsersCtrl.instance = new UsersCtrl();
    }

    return UsersCtrl.instance;
  }

  notifyUserStateChange = () => {
    const instance = UsersCtrl.getInstance();
    instance.userStateChange.next(Date.now());
  };

  onLogoutSuccess = () => {
    const instance = UsersCtrl.getInstance();
    LoadingController.removeLoadingMask();
    removeUserLocalData();
    cleanupToken();
    instance.notifyUserStateChange();
    const urlPath = getURLPath();

    const userDataString = localStorage.getItem('fe-user-data');
    const userData = userDataString ? JSON.parse(userDataString) : {};
    const nats_member_id = userData.memberid;

    localStorage.removeItem(AGE_WARNING_OVERLAY);

    onEventGtag({
      data: {
        event: 'userLoggedOut',
        userType: 'nonmember',
        userStatus: 'loggedout',
        logged: "No",
        logoutURL: urlPath,
        hostname: window.location.hostname.toLowerCase().trim(),
        nats_member_id: nats_member_id
      }
    });

    navigate(`/${routes.specialOffers}`);
  }

  onRequestFailure = (error) => {
    logError(error);
    LoadingController.removeLoadingMask();
  };

  signOutUser = (message) => {
    const instance = UsersCtrl.getInstance();
    LoadingController.showLoadingMask(message);
    authLogout()
      .then(instance.onLogoutSuccess)
      .catch(instance.onRequestFailure);
  };
}

export const UsersController = UsersCtrl.getInstance();