import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageLoadingSkeleton from '../ImageLoadingSkeleton/ImageLoadingSkeleton';
import './SceneImage.scss';

const SceneImage = (props) => {
  const { url, alt, hidden } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const defaultAltText = "BoxCoverGif";

  useEffect(() => {
    const image = new Image();
    image.onerror = () => {
      setError(`SceneImage - error loading ${url}`);
      setLoading(false);
    };
    image.onload = () => setLoading(false);
    image.src = url

    return () => setLoading(true);
  }, [url]);

  if (hidden) return null;

  if (error) {
    return (
      <div className="SceneImage">
        <div className='ErrorText'>Missing image...</div>
      </div>
    );
  }

  if (loading) return <ImageLoadingSkeleton className='SceneImageLoader' />;

  return (
    <div className="SceneImage">
      <img
        src={url} alt={alt || defaultAltText}
        width="320"
        height="180"
      />
    </div>
  )
};

SceneImage.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string.isRequired,
  hidden: PropTypes.bool
};

export default SceneImage;