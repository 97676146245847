import MoviesScenesGrid from '../../../components/MoviesScenesGrid/MoviesScenesGrid'
import PageIndicator from '../../../components/PageIndicator/PageIndicator';

import { clearSpecialCharactersReplacements } from '../../../services/util-service/util.service';
import { navigationOptions } from '../../../constants/navigation';
import useMoviesScenesData from '../../../hook/useMoviesScenesData';

import './DirectorPage.scss';

const DirectorPage = (props) => {
  const { directorId, directorName, location = {} } = props;

  const {
    videos,
    pagination: { page, last_page },
    sort,
    type,
    loading,
    error,
    onPageChange,
    onTypeChange,
    onSortByClick
  } = useMoviesScenesData({
    location,
    hookParams: { directors_id: directorId }
  });

  const renderPagination = () => {
    if (page === 1 && last_page === 1) return null;
    if (error) return null;
    return (
      <PageIndicator
        page={page}
        pageCount={last_page}
        onPageChange={onPageChange}
        scrollIntoView='.ShowMeSortByNavigation'
      />
    )
  };

  return (
    <div className='DirectorPage'>
      <MoviesScenesGrid
        data={videos}
        loading={loading}
        type={type}
        sort={sort}
        onShowMeClick={onTypeChange}
        onSortByClick={onSortByClick}
        error={error}
        title={clearSpecialCharactersReplacements(directorName)}
        subTitle={type === navigationOptions.scenes ? 'Most recent scenes' : 'Most recent movies'}
      />
      {renderPagination()}
    </div>
  )
};

DirectorPage.displayName = 'DirectorPage';

export default DirectorPage;