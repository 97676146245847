import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import './ImageLoaderComponent.scss';

const ImageLoaderComponent = (props) => {
  const {
    className,
    url,
    backImageUrl,
    onClick,
    alt = '',
    width,
    height,
    noImageText
  } = props;

  const { t } = useTranslation();
  const [frontImage, setFrontImage] = useState(true);

  const renderImage = () => {
    const src = frontImage ? url : backImageUrl;

    return (
      <>
        {!src ?
          <div className="ImageComponent-notFound">
            {noImageText ? noImageText : t('ImageLoaderComponent.notFound')}
          </div>
          :
          <img
            onClick={onClick}
            src={src}
            alt={alt}
            width={width}
            height={height}
          />
        }
      </>
    );
  };

  const classes = ['ImageComponent'];

  if (className) {
    classes.push(className);
  }

  return (
    <div
      className={classes.join(' ')}
      onMouseEnter={() => backImageUrl ? setFrontImage(false) : null}
      onMouseLeave={() => backImageUrl ? setFrontImage(true) : null}
    >
      {renderImage()}
    </div>
  );
};

ImageLoaderComponent.displayName = 'ImageLoaderComponent';

ImageLoaderComponent.propTypes = {
  backImageUrl: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func,
  url: PropTypes.string,
  alt: PropTypes.string,
  noImageText: PropTypes.string
};

export default ImageLoaderComponent;
