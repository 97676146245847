import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import { routes } from "../../../../services/navigation/navigation.service.routes";
import MoviesScenesGrid from '../../../../components/MoviesScenesGrid/MoviesScenesGrid';
import useInViewport from '../../../../hook/useInViewport';
import useMoviesScenesData from '../../../../hook/useMoviesScenesData';
import { SecondaryNavigation } from '../../../../constants/navigation';

const MostPopularGrid = ({ hidePoster }) => {
	const { t } = useTranslation();

	const containerRef = useRef(null);
	const isVisible = useInViewport(containerRef);

	const { videos, loading, error } = useMoviesScenesData({
		isVisible,
		videosSortType: SecondaryNavigation.MostWatched
	});

	return (
		<MoviesScenesGrid
			title={t('MostPopularGrid.mainTitle')}
			data={videos}
			loading={loading}
			error={error}
			viewAllLink={{ text: 'View All', url: routes.mostWatched }}
			hideNavigation
			hidePoster={hidePoster}
			refProp={containerRef}
		/>
	)
};

MostPopularGrid.displayName = 'MostPopularGrid';

export default MostPopularGrid;