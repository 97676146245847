import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { createRequestInterceptor, createResponseInterceptor } from "./axios-interceptor";
import { env } from './env';
/* global dataLayer */
/* global gtag */

// URLs for your external scripts (access the global variables directly)
const gaScriptUrl1 = `https://www.googletagmanager.com/gtag/js?id=${window.gaMeasurementId}`;
const gaScriptUrl2 = `https://www.googletagmanager.com/gtag/js?id=${window.gaUniversalMeasurementId}`;
const gtmScriptUrl = `https://www.googletagmanager.com/gtm.js?id=${window.gaGTMMeasurementId}`;

axios.defaults.baseURL = env.REACT_APP_URL;
createRequestInterceptor();
createResponseInterceptor();

function initializeReactApp() {
	ReactDOM.render(
		<App />,
		document.getElementById('root')
	);
}

//React App First
initializeReactApp();

// Asynchronously Load Google Analytics script
// Define the gtag function only once, if it hasn't been defined yet
window.dataLayer = window.dataLayer || [];
window.gtag = window.gtag || function () { dataLayer.push(arguments); };

window.loadScript = function (src, callback) {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.src = src;
	script.onload = () => {
		callback();
	};
	script.onerror = () => {
		console.error(`Error loading script: ${src}`);
	};
	document.head.appendChild(script);
}

// Load the first GA script
window.loadScript(gaScriptUrl1, () => {
	// First GA property configuration
	gtag('js', new Date());
	gtag('config', window.gaMeasurementId1);
});

// Load the second GA script
window.loadScript(gaScriptUrl2, () => {
	// Second GA property configuration
	gtag('config', window.gaUniversalMeasurementId);
});

window.loadScript(gtmScriptUrl, () => {
	// Google Tag Manager is now loaded
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();