import axios from 'axios';
import { BaseVideoPlayerController } from "@falconstudios/ns-player";
import { isUserLoggedIn } from '../token-service/token.service';
import { isWeb } from '../util-service/util.service';

export const urls = {
  stream: '/frontend/streaming/aebn/movie',
  stills: '/frontend/movies',
  download: '/frontend/download/aebn'
};

const ASPECT_RATIO = 16 / 9;
// const keys = ['360p', '480p', '720p', '1080p', '2k', '3k', '4k', 'unrestricted'];
// const heights = [360, 480, 720, 1080, 2048, 2880, 3840, Number.MAX_SAFE_INTEGER];
const heights = [360, 480, 720, 1080, 1440, 1620, 2160, Number.MAX_SAFE_INTEGER];
const bitrates = [1024, 2048, 4000, 6000, 7500, 9000, 12000, 100000];
const sizes = heights.reduce((obj, width) => {
  obj[Math.round(width * ASPECT_RATIO)] = width;
  return obj;
}, {});
const widths = heights; Object.keys(sizes).map(key => Number(key)).sort((a, b) => a < b);
export const getBitrate = () => {
  const screenWidth = window.innerWidth || window.outerWidth;
  let i = 1;
  let index = 0;
  let nextIndex = 1;
  while (i < heights.length) {
    if (widths[i] <= screenWidth) {
      index = i;
    } else {
      nextIndex = i;
      break;
    }
    i++;
  }
  let bitrate = bitrates[index];
  if ([index, nextIndex].indexOf(bitrates.length - 1) !== -1) {
    bitrate = bitrates[bitrates.length - 1];
    return bitrate;
  }
  if (nextIndex !== -1) {
    bitrate = (bitrates[nextIndex] - bitrates[index]) / 2 + bitrates[index];
  }
  return bitrate;
}

export const getVideoStream = ({
  movieId,
  sceneId,
  start_time,
  duration,
  orientation,
  max_bitrate,
  format = BaseVideoPlayerController.getUserStreamVideoFormat(),
  isPreview,
  smoothStreaming
}) => {
  if (!max_bitrate) {
    const logged = isUserLoggedIn();
    const useBestQuality = logged && isWeb();
    max_bitrate = useBestQuality ? BaseVideoPlayerController.getUnrestrictedBitrate() : BaseVideoPlayerController.getMaxBitrate();
    max_bitrate = getBitrate();
  }

  const params = {
    max_bitrate: max_bitrate || 1200
  };

  if (sceneId) {
    params.scenes_id = sceneId;
  }
  if (start_time) {
    params.start_time = start_time;
  }
  if (duration) {
    params.duration = duration;
  }
  if (orientation) {
    params.orientation = orientation;
  }
  if (smoothStreaming) {
    format = 'SmoothStreaming';
    params.max_bitrate = 4800; // 720p
  }
  if (format) {
    params.format = format;
  }
  if (isPreview) {
    params.is_preview = 1;
  }
  return axios.get(`${urls.stream}/${movieId}`, { params });
};

export const getVideoStills = (movieId, start_time = undefined, duration = undefined) => {
  return axios.get(`${urls.stills}/${movieId}/stills/aebn/`, {
    params: {
      start_time,
      duration
    }
  });
};

export const getVideoDownloadInfo = (id) => {
  return axios.get(`${urls.download}/movie/${id}/info`, {
    params: {
      id
    }
  });
};

export const getVideoDownloadUrl = async (id, bitrate, type) => {
  return axios.get(`${urls.download}/${type}/${id}/url`, {
    params: {
      id,
      bitrate
    }
  });
};