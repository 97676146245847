import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import { ROUTES } from '../../services/navigation/navigation.routes';
import {
  displayErrorNotification,
  displayInfoNotification
} from '../../services/notification-service/notification.service';
import { subscribeToNewsletter } from '../../services/newsletter-service/newsletter.service';
import { isUserLoggedIn } from '../../services/token-service/token.service';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';
import ApplicationLogo from '../ApplicationLogo/ApplicationLogo';

import { ReactComponent as TwitterIcon } from '../../images/twitter_icon.svg';
import { ReactComponent as InstagramIcon } from '../../images/instagram_icon.svg';
import { links } from '../../links';
import { env } from '../../env';

import './Footer.scss';

function Footer() {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const onSubscribe = () => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(email)) {
        subscribeToNewsletter(email)
            .then(() => {
              displayInfoNotification({
                duration: 3,
                message: t('LegalNotice.subscribeSuccessMsg', { email }),
                title: t('LegalNotice.subscribeSuccess')
              });
              setEmail('');
            })
            .catch(err => {
              console.log(err);
            });
      } else {
        displayErrorNotification({
          duration: 3,
          message: t('LegalNotice.usernameInvalid'),
          title: t('LegalNotice.invalidEntry')
        });
        inputRef.current.focus();
      }
    }
  };

  return (
      <div className='Footer'>

        <div className='DesktopLogoContainer text-center'>
          <ApplicationLogo notLink />
        </div>

        <div className='NewsletterContainer d-flex align-center justify-center flex-wrap'>
          <span>Newsletter Sign Up</span>
          <span className='GoldText'>Special Offers, Free Stuff, Latest News & More!</span>
          <div className='InputContainer d-flex align-center'>
            <input
                name='email'
                autoComplete='off'
                ref={inputRef}
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder='Enter E-mail Address Here' />
            <button type='button' onClick={onSubscribe}>Subscribe</button>
          </div>
        </div>

        <div className='SocialIconsContainer'>
          {!isUserLoggedIn() && (
              <div className='Heading'>Have an account? <span onClick={() => SignInController.showSignInDialog()}>Sign in</span></div>
          )}

          <a className='Icons' target='_blank' rel='noreferrer' href={links.twitter}><TwitterIcon /></a>
          <a className='Icons' target='_blank' rel='noreferrer' href={links.instagram}><InstagramIcon /></a>
        </div>

        <div className='FooterLinks'>
          <Link to={ROUTES.help}>FAQ &amp; Support</Link>
          <Link to={ROUTES.termsOfUse}>Terms of use</Link>
          <Link to={ROUTES.privacyPolicy}>Privacy policy</Link>
          <a href='https://www.nakedswordcash.com/' target='_blank' rel='noreferrer'>Affiliates</a>
        </div>

        <div className='FooterText'>
            <div className='FT-Inner'>
                <p className='mb'>All performers are at least 18 years of age. <Link to={ROUTES.compliance}>"18 U.S.C 2257 Record Keeping Requirements Compliance Statement"</Link>
                </p>
                <p>If you have any questions please contact a NakedSword customer service specialist by clicking on the "FAQ &amp; SUPPORT" tab or calling U.S. U.S. (TOLL FREE) 888.207.4534 / International 980.505.8342.</p>
                <p className='mb'>Support - Monday to Friday 10am-8pm All times are US Eastern Time. Closed Saturday and Sunday.</p>
                <div className="ContentRemovalContainer mb">
                    <a href="https://www.aebn.com/contentremoval" target="_blank" rel="noreferrer">content removal</a>
                    <a href="mailto:support@adultpaysitesupport.com">billing support</a>

                </div>
                <div className="mb">For Epoch billing support click <a href="https://epoch.com" target="_blank" rel="noreferrer">HERE</a>.</div>
                <div className="mb">
                    <a href='https://www.rtalabel.org' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/rta-logo.svg)' }}>rta logo</a>
                    <a href='https://www.freespeechcoalition.com/' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/fsc-logo.svg)' }}>fsc logo</a>
                </div>
                <div>Copyright © {env.REACT_APP_SITE_NAME} All Rights Reserved.</div>
            </div>
        </div>
      </div>
  )
}

export default Footer;