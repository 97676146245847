import { frontPosterSizes } from "../../pages/MainDashBoard/MainDashboard";

export const findExclusiveImages = (images) => {
  let imagesCount = 3;
  const exclusiveImages = {
    small: '',
    medium: '',
    large: ''
  };
  let item = images.length;
  let currentImage;
  while (item) {
    item--;
    currentImage = images[item];
    if (imagesCount && matchExclusiveImage(currentImage) && currentImage.url.indexOf('master') === -1) {
      imagesCount--;
      if (!exclusiveImages.small) {
        exclusiveImages.small = currentImage;
      } else if (!exclusiveImages.medium) {
        exclusiveImages.medium = currentImage;
      } else {
        exclusiveImages.large = currentImage;
      }
    }
  }

  return [exclusiveImages.small, exclusiveImages.medium, exclusiveImages.large];
};

export const getExclusiveImage = (images = []) => {
  if (images.length !== 0) {
    const exclusiveImages = images.filter(img => img?.type?.toLowerCase() === 'exclusive');
    const groupByMasterId = findExclusiveImages(exclusiveImages);
    const matchImageWidth = getImageByWidth(groupByMasterId);

    if (matchImageWidth) {
      const { url, alt, width, height } = matchImageWidth;
      return { url, alt, width, height };
    }
  }

  return {};
};

export const getNumberOneImage = (images = []) => {
  if (images.length !== 0) {
    const numberOneImages = images.filter(img => img.type?.toLowerCase()?.includes('numberone'));
    const matchImageWidth = getImageByWidth(numberOneImages);

    if (matchImageWidth) {
      const { url, alt, width, height } = matchImageWidth;
      return { url, alt, width, height };
    }
  }
  return {};
};

export const matchExclusiveImage = ({ type }) => type === 'Exclusive';

export const getPosterImage = (images = [], isFrontCover = true, maxWidth = frontPosterSizes.details) => {
  if (!Array.isArray(images)) {
    console.error(`getPosterImage - ${images} must be an array!`);
    return {};
  }

  if (typeof maxWidth !== 'number') {
    maxWidth = parseInt(maxWidth);
    if (isNaN(maxWidth)) {
      console.error(`getPosterImage - ${maxWidth} is not a number!`);
    }
  }

  const coverImages = [];
  let poster;

  if (images.length !== 0) {
    images.forEach((img = {}) => {
      if (Object.keys(img).length === 0) return {};

      const type = img.type.toLowerCase();

      if (type.includes('box cover')) {
        if (!type.includes('back') && isFrontCover) {
          coverImages.push({ url: img.url, alt: img.alt, width: img.width, height: img.height });
        } else if (type.includes('back') && !isFrontCover) {
          coverImages.push({ url: img.url, alt: img.alt, width: img.width, height: img.height });
        }
      }
    });

    if (coverImages.length !== 0) {
      const getUniqueWidth = [...new Set(coverImages.map(item => item.width))];
      const matchWidth = getUniqueWidth.reduce((a, b) => {
        return Math.abs(b - maxWidth) < Math.abs(a - maxWidth) ? b : a;
      });
      poster = coverImages.find(item => item.width === matchWidth);
    }
  }

  if (!poster?.url) {
    poster = images.find(item => item?.url);
  }

  return poster || {};
};

export const getImageByWidth = (images = [], screenWidth = window.innerWidth) => {
  const imgObj = {};

  if (Array.isArray(images) && images.length !== 0) {
    const availableWidths = [];

    images.forEach(img => {
      availableWidths.push(img?.width);
      const matchWidth = availableWidths.reduce((a, b) => {
        return Math.abs(b - screenWidth) < Math.abs(a - screenWidth) ? b : a;
      });
      Object.assign(imgObj, images.find(item => item.width === matchWidth));
    });
  }

  return imgObj;
};