import React, { useEffect } from 'react';
import { Router, navigate } from "@reach/router";

import { ErrorBoundary } from '../../components/ErrorBoundary/ErrorBoundary';
import ApplicationLogo from '../../components/ApplicationLogo/ApplicationLogo';
import AvsHome from "./AvsHome/AvsHome";
import { AGE_WARNING_OVERLAY } from '../../components/AgeWarningOverlay/AgeWarningOverlay';
import RenderHtmlContent from '../../components/RenderHtmlContent/RenderHtmlContent';
import { LoadingText } from "../../components/LoadingMask/LoadingMask.js";
import AvsCancel from './AvsCancel/AvsCancel';
import AvsSuccess from './AvsSuccess/AvsSuccess';
import AvsError from './AvsError/AvsError';
import AvsNotFound from './AvsNotFound/AvsNotFound';

import { ROUTES } from "../../services/navigation/navigation.routes";
import { useGtag } from '../../hook/useGtag';
import { useHtmlContentContext } from '../../context/HtmlContentContext';

import './AvsPage.scss';

export const sessionExpires = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  return date.toLocaleDateString();
};

export const RETURL = 'returl';

const AvsPage = () => {
  const { htmlContent, htmlContentLoading } = useHtmlContentContext();
  const path = window.location.pathname;
  let infoPageRoute = ROUTES.ageVerificationFaq; // privacy, tos, help

  if (path.includes(ROUTES.ageVerificationFaq)) {
    infoPageRoute = ROUTES.ageVerificationFaq;
  } else if (path.includes(ROUTES.ageVerificationTos)) {
    infoPageRoute = ROUTES.ageVerificationTos;
  } else if (path.includes(ROUTES.ageVerificationPrivacy)) {
    infoPageRoute = ROUTES.ageVerificationPrivacy;
  }

  const pageUrl = infoPageRoute.slice(infoPageRoute.lastIndexOf('/') + 1); // privacy, tos, help

  const getSearchParams = () => {
    try { // window.atob or decodeURIComponent might throw an exception  
      const searchParams = window.location.search ? window.location.search?.slice(1) : null;

      if (searchParams) {
        const createURL = new URLSearchParams(searchParams);
        const getReturnURL = createURL.get(RETURL);
        const base64decode = window.atob(getReturnURL);
        const decodeURI = decodeURIComponent(base64decode);

        if (decodeURI && decodeURI.startsWith('http')) {
          localStorage.setItem(RETURL, decodeURI); // params are lost during YOTI process 
        }
      }
    } catch (err) {
      console.error('Parameters are not encoded correctly!');
    }
  };

  getSearchParams();

  useEffect(() => {
    const html = document.documentElement;
    if (html) {
      html.style.overflow = 'auto';
      html.scrollTop = 0;
    }
  }, [path]);

  useEffect(() => {
    localStorage.setItem(AGE_WARNING_OVERLAY, 1);
  }, []);

  const linkToHome = path.includes(ROUTES.ageVerificationSuccess) ? ROUTES.root : `/${ROUTES.ageVerificationLanding}`;

  const renderLogo = () => {
    return (
      <div className='cursor-pointer' onClick={() => navigate(linkToHome)}>
        <ApplicationLogo notLink />
      </div>
    );
  };

  useGtag({
    data: {
      event: 'page_navigation',
      stream_id_1: window.gaMeasurementId ?? 'G-CVZ4RHQ130',
      stream_id_2: window.gaUniversalMeasurementId ?? 'G-ZGSEPJTQ7G',
      page: path,
    }
  });

  return (
    <ErrorBoundary>
      {htmlContentLoading ? <LoadingText /> : (
        <div className="AvsPage">
          {renderLogo()}
          <Router>
            <AvsHome path={ROUTES.ageVerificationLanding} />
            <AvsCancel path={ROUTES.ageVerificationCancel} />
            <AvsSuccess path={ROUTES.ageVerificationSuccess} />
            <AvsError path={ROUTES.ageVerificationError} />
            <RenderHtmlContent path={infoPageRoute} htmlContent={htmlContent} dataLabel={pageUrl} />
            <AvsNotFound linkToHome={linkToHome} default />
          </Router>
        </div>
      )}
    </ErrorBoundary>
  );
};

AvsPage.displayName = 'AvsPage';

export default AvsPage;
