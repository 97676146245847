import { createContext, useContext, useState, useEffect } from 'react';
import { getHtmlContent } from '../services/properties/properties.service';

const HtmlContentContext = createContext();

const HtmlContentContextProvider = ({ children }) => {
  const [state, setState] = useState({
    htmlContent: {},
    htmlContentLoading: true
  });

  useEffect(() => {
    getHtmlContent()
      .then(resp => {
        const response = resp?.data?.data || {};
        if (response && Object.keys(response).length !== 0) {
          setState(prevState => ({ ...prevState, htmlContent: { ...response } }));
        }
      })
      .catch(err => console.log(err))
      .finally(() => setState(prevState => ({ ...prevState, htmlContentLoading: false })));
  }, []);

  return (
    <HtmlContentContext.Provider value={{ ...state }}>
      {children}
    </HtmlContentContext.Provider>
  )
};

const useHtmlContentContext = () => {
  const context = useContext(HtmlContentContext);
  if (context === undefined) {
    throw new Error('HtmlContentContext must be used within a HtmlContentContextProvider!');
  }
  return context;
};

export { HtmlContentContextProvider, useHtmlContentContext };