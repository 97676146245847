import React, { Component } from 'react';
import { navigate } from "@reach/router";
import { withTranslation } from "react-i18next";
import { getURLPath } from "../../services/navigation/navigation.service.js";

import { authLogin, authMe } from "../../services/auth-service/auth.service";
import { EXPIRES, saveToken, saveYuliCookie } from "../../services/token-service/token.service";
import { setUserLocalData } from "../../services/users-service/users.service";
import { showStreamBlockedPage, showForgotPasswordPage, showHelpPage, showJoinNowPage } from "../../services/navigation/navigation.service";
import { ROUTES } from '../../services/navigation/navigation.routes.js';
import { PREVIEWS_MAX_COUNT, setBrowserCookie } from '../../services/util-service/util.service.js';

import ModalDialog from "../ModalDialog/ModalDialog";
import MemberPWReminder from "../MemberPWReminder/MemberPWReminder";
import { displayBenefitsModalDialog } from '../../pages/MainDashBoard/MemberDealsPage/MemberDealsLoginModal.js';


import { LoadingController } from "../../controllers/loading-controller/loading.controller";
import { SignInController } from "../../controllers/sign-in-controller/sign-in.controller";
import { UsersController } from "../../controllers/users-controller/users.controller";
import { ModalController } from "../../controllers/modal-controller/modal.controller";
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController.js';
import { onEventGtag } from '../../hook/useGtag.js';

import './SignIn.scss';
import '../MemberPWReminder/MemberPWReminder.scss';

class SignIn extends Component {
  modalRef = React.createRef();

  state = {
    show: false,
    password: '',
    showReset: false,
    username: '',
    showPassword: false
  };

  closeDialog = () => {
    SignInController.closeSignInDialog();
  };

  getModal = () => this.modalRef.current;

  isDataValid = () => {
    const { password, username } = this.state;
    return password && username;
  };

  onChange = (key, event) => {
    const { value } = event.target;
    this.setState({
      [key]: value
    });
  };

  onLoginSuccess = async (response) => {
    const { expiresIn, jwt } = response.data.data;
    const urlPath = getURLPath();
    const userDataString = localStorage.getItem('fe-user-data');
    const userData = userDataString ? JSON.parse(userDataString) : {};
    const nats_member_id = userData.memberid;

    // Check if the current environment property ID matches NakedSword's or GuerillaPorn's ID then launch MemberBenefitsLoginModal
    if (process.env.REACT_APP_PROPERTY_ID === "1" || process.env.REACT_APP_PROPERTY_ID === "22") {
      displayBenefitsModalDialog(userData);
    }

    onEventGtag({
      data: {
        event: 'userLoggedIn',
        userType: 'member',
        userStatus: 'loggedin',
        loginURL: urlPath,
        nats_member_id: nats_member_id,
        hostname: window.hostname,
        logged: "Yes"
      }
    });

    saveToken(jwt, expiresIn);
    UsersController.notifyUserStateChange();
    this.closeDialog();

    // delete preview_max_count cookie when log in
    const { preview_max_count } = DashboardController.getWhitelabelConfig();
    setBrowserCookie(PREVIEWS_MAX_COUNT, preview_max_count);
  };

  onRequestFailure = (error) => {
    LoadingController.removeLoadingMask();
    const { status } = error?.response || {};
    switch (status) {
      case 401: // 'Unauthorized login'
        this.setState({ showReset: true });
        break;
      case 403: // "IP Limit Exceeded"
        this.closeSignInDialogAndGoToStreamBlockedPage();
        break;
      default:
        break;
    }
  };

  showPassword = () => {
    this.setState(prevState => ({ showPassword: !prevState.showPassword }))
  }

  renderPasswordInput = () => {
    const { password, showPassword } = this.state;
    const { t } = this.props;
    return (
      <div className="InputWrapper">
        <i className="fas fa-lock" />
        <input
          value={password}
          className="Input Password"
          type={showPassword ? 'text' : 'password'}
          placeholder={t('SignIn.password')}
          onChange={this.onChange.bind(this, 'password')}
          onKeyUp={this.signInUserOnEnterIfValid}
        />
        <i
          className={`fas ${showPassword ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer`}
          title={`${showPassword ? 'Hide password' : 'Show password'}`}
          onClick={this.showPassword}
        />
      </div>
    );
  };

  renderUsernameInput = () => {
    const { username } = this.state;
    const { t } = this.props;
    return (
      <div className="InputWrapper">
        <i className="fas fa-user" />
        <input value={username}
          className="Input Username"
          placeholder={t('SignIn.username')}
          onChange={this.onChange.bind(this, 'username')}
          onKeyUp={this.signInUserOnEnterIfValid} />
      </div>
    );
  };

  renderSignInButton = () => {
    const { t } = this.props;
    const classes = ['SignInButton'];
    let disabled = false;
    if (!this.isDataValid()) {
      classes.push('Disabled');
      disabled = true;
    }
    return (
      <button className={classes.join(' ')}
        disabled={disabled}
        onClick={this.signInUser}>
        {t('SignIn.signIn')}
      </button>
    );
  };

  closeSignInDialogAndGoToStreamBlockedPage = () => {
    this.closeDialog();
    showStreamBlockedPage();
  };

  toggleForgotPasswordVisibility = () => {
    this.setState((prevState) => ({
      show: !prevState.show
    }));
  };

  renderForgotPassword = () => {
    const { t } = this.props;
    const { show, isModal } = this.state;
    return (
      <div className="ForgotPswContainer">
        <div onClick={this.toggleForgotPasswordVisibility}  >
          {show ? <span className="closeforgot fa fa-times" title={t('SignIn.closeForgot')} /> : <span className='ForgotPassword'>{t('SignIn.forgotPassword')}</span>}
        </div>
        {show ? <MemberPWReminder isModal={isModal} /> : null}
      </div>
    );
  };

  renderJoinHere = () => {
    const { t } = this.props;
    return <span className="JoinHere" onClick={showJoinNowPage}>{t('SignIn.joinHere')}</span>;
  };

  renderInvalidUsernamePasswordMessage = () => {
    const { t, isModal } = this.props;

    return (
      <div className="InvalidUsernamePasswordMsg">
        <i className="fas fa-exclamation-triangle" />
        <div className="InvalidUsernamePasswordMsg-text-wrapper">
          <div className="InvalidUsernamePasswordMsg-title">
            {t('SignIn.invalidUsernamePasswordTitle')}
          </div>

          {isModal && (
            <>
              <div className="InvalidUsernamePasswordMsg-text"
                onClick={this.showFAQIfClicked}
                dangerouslySetInnerHTML={{
                  __html: t('SignIn.invalidUsernamePasswordFAQ')
                }}>
              </div>
              <div className="InvalidUsernamePasswordMsg-text"
                onClick={this.showResetPasswordIfClicked}
                dangerouslySetInnerHTML={{
                  __html: t('SignIn.invalidUsernamePasswordResetPasswordText')
                }}>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  renderMainView = () => {
    const { showReset } = this.state;
    const { t, isModal } = this.props;
    return (
      <div className="SignIn-inner">
        {showReset ? this.renderInvalidUsernamePasswordMessage() : null}
        <div className="Column Center Stretch">
          {this.renderUsernameInput()}
          {this.renderPasswordInput()}
          {this.renderSignInButton()}
          {isModal && (
            <div className="Row NotAMember">
              <div className="NotAMember">{t('SignIn.notAMember')}</div>
              {this.renderJoinHere()}
            </div>
          )}
          <div className="Column Center">
            {this.renderForgotPassword()}
          </div>
        </div>
      </div>
    );
  };

  signInUser = () => {
    const { password, username } = this.state;
    if (password && username) {
      const message = this.props.t('SignIn.authenticatingUserMsg');
      LoadingController.showLoadingMask(message);
      authLogin(username, password)
        .then((resp = {}) => {
          const { expiresIn } = resp.data?.data || {};
          this.onLoginSuccess(resp)
            .then(() => {
              authMe()
                .then((resp = {}) => {
                  const { data } = resp.data || {};
                  setUserLocalData(data);
                  data[EXPIRES] = expiresIn;
                  saveYuliCookie(data);
                  LoadingController.removeLoadingMask();

                  const urlPath = getURLPath();
                  if (urlPath.includes(ROUTES.signin)) {
                    navigate(`/${ROUTES.myAccount}`)
                  } else if (urlPath.includes(ROUTES.ageVerificationLanding)) {
                    window.location.href = '/';
                  }
                })
                .catch(err => {
                  LoadingController.removeLoadingMask();
                  console.log(err);
                });
            })
        })
        .catch(this.onRequestFailure);
    }
  };

  signInUserOnEnterIfValid = (event) => {
    if (event.key === 'Enter' && this.isDataValid()) {
      this.signInUser();
    }
  };

  showFAQIfClicked = (event) => {
    const { target } = event;
    if (target.nodeName === 'A' && target.className === 'FAQLink') {
      showHelpPage();
      SignInController.closeSignInDialog();
    }
  };

  showResetPasswordIfClicked = (event) => {
    const { target } = event;
    if (target.nodeName === 'A' && target.className === 'ForgotPasswordLink') {
      ModalController.closeModal()
      showForgotPasswordPage();
    }
  };

  render() {
    const { isModal } = this.props;
    const modalContent = this.renderMainView();

    if (isModal) {
      const style = {};
      const header = document.querySelector('.MainDashboardHeader');

      if (header) {
        style.top = Math.floor(header.offsetTop + header.getBoundingClientRect().height);
      }

      return (
        <ModalDialog className="SignInModal"
          style={style}
          forwardedRef={this.modalRef}
          closeModal={this.closeDialog}>
          {modalContent}
        </ModalDialog>
      );
    } else {
      return (
        <div className="SignIn">
          {modalContent}
        </div>
      );
    }
  }
}

SignIn.defaultProps = {
  isModal: true
};


export default withTranslation()(SignIn);