import { env } from "./env";
import { getUserNATSCode } from "./services/nats-service/nats.service";


if (!env.REACT_APP_JOIN_NOW_URL) {
  console.error("REACT_APP_JOIN_NOW_URL is missing in env.js file!");
}

let envJoinUrl = env.REACT_APP_JOIN_NOW_URL.endsWith('/') ?
  env.REACT_APP_JOIN_NOW_URL :
  env.REACT_APP_JOIN_NOW_URL + '/';

// get value from storage/url after nats code is changed
export const createJoinLinkFromLastCode = (code) => `${envJoinUrl}${code}/join`;

export const links = {
  billingAndSupport: `mailto:${env.REACT_APP_SUPPORT_EMAIL}`,
  joinNow: `${envJoinUrl}${getUserNATSCode()}/join`,
  instagram: 'https://www.instagram.com/nkdsword/',
  live: 'https://nakedswordlive.com/',
  twitter: 'https://twitter.com/nakedswordcom',
  ondemand: "https://vod.nakedsword.com/gay?campaignId=19178&subAffId=NSVODHOME",
  webmasters: env.REACT_APP_WEBMASTERS_URL,
  webMastersSignIn: `${env.REACT_APP_WEBMASTERS_URL}/signup/password.php`,
  joshmoore: "https://www.google.com"
};