import { measureHeight } from "react-div-100vh";

export const PREVIEWS_MAX_COUNT = 'PMC';

export const openNewBrowserTab = (url) => {
	window.open(url, "_blank");
};

export const getMiddleSectionHeight = (removeHeader, removeActions) => {
	let full;
	if (!isWeb()) {
		full = measureHeight();
		if (removeHeader) {
			full -= 65;
		}
		if (removeActions) {
			full -= 56;
		}
	}

	return full;
};

export const removeSpecialCharacters = (text, removeSpaces = false, removeBackSlashes = false) => {
	if (text) {
		let replace = text.trim().replace(/\//g, removeBackSlashes ? "" : "_");
		replace = replace.replace(/ /g, removeSpaces ? "" : "-");
		replace = replace.replace(/:/g, "-");
		return replace.toLowerCase();
	}
	return text;
};

export const clearSpecialCharactersReplacements = (text) => {
	if (!text) return;
	return text.replace(/-/g, " ").replace(/_/g, "/").replace('/', '');
};

export const stringToSlug = (text) => {
	return text
		.toLowerCase()
		.replace(/ /g, "-")
		.replace(/[^\w\-/]+/g, "")
		.replace(/(-)(?=.*\1)/g, "");
};

export const matchMediaRule = (rule) => {
	return window.matchMedia(`(${rule})`).matches;
};

export const isWeb = () => matchMediaRule("min-width: 960px");

export const isMobileWidth = () => matchMediaRule("max-width: 640px");

const ExternalLinkRegex = new RegExp(/^https|^http|^www/);
export const isExternalLink = (link) => {
	return ExternalLinkRegex.test(link);
};

export const setContentScrolltop = (elem) => {
	const elementPosition = elem ? document.querySelector(elem)?.offsetTop : 0;
	const header = document.querySelector('.MainDashboardHeader');
	const mainContent = document.querySelector('.MainContent');
	const mainContainer = document.querySelector('.MainContainer');

	if (isWeb()) {
		if (mainContent) {
			mainContent.scrollTop = elementPosition - (header?.offsetHeight * 2);
			return;
		}
	}

	if (mainContainer) {
		mainContainer.scrollTop = elementPosition - header?.offsetHeight - 10;
	}
};

export const getCookie = (name) => {
	if (!name) {
		console.log('No cookie name provided!');
		return;
	}
	const cookie = document.cookie.split('; ').find(row => row.startsWith(`${name}=`))?.split('=')[1];

	return cookie;
};

export const setBrowserCookie = (name, value, expires) => { // expires in hours
	if (!name) {
		console.log('setBrowserCookie - no name provided');
		return;
	};
	if (typeof expires == 'object' || typeof expires == 'function') {
		console.log('setBrowserCookie - expires value not primitive!', expires);
		return;
	}
	const cookieExpires = expires ? new Date(new Date().getTime() + expires * 60 * 60 * 1000).toUTCString() : 'Thu, 01 Jan 1970 00:00:00 GMT';
	document.cookie = `${name}=${value}; expires=${cookieExpires}; path=/;`;
};