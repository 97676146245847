import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isUserLoggedIn } from '../../services/token-service/token.service';
import { showMyAccountPage } from '../../services/navigation/navigation.service';
import { UsersController } from '../../controllers/users-controller/users.controller';
import { SignInController } from '../../controllers/sign-in-controller/sign-in.controller';

import BoxCoverPlayerDetailsComponent from '../../components/BoxCoverPlayerDetailsComponent/BoxCoverPlayerDetailsComponent';
import ModalContainer from '../../components/ModalContainer/ModalContainer';
import Notifications from '../../components/Notifications/Notifications';

import './StreamTest.scss';

/*
This component is used for diagnostics outside of the age verification process, so we can determine if the WL is working or not
 */

const StreamTest = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const html = document.documentElement;
        if (html) {
            html.style.overflow = 'auto';
            html.scrollTop = 0;
        }
    }, []);

    const renderUserActions = () => {
        return (
            <div className='AccountContainer'>
                {isUserLoggedIn() ? (
                    <>
                        <span onClick={() => UsersController.signOutUser(t('MainDashboardHeader.loginOutMsg'))}>
                            {t('MainDashboardHeader.signOut')}
                        </span>|
                        <span onClick={() => showMyAccountPage()}>{t('MainDashboardHeader.myAccount')}</span>
                    </>
                ) : (
                    <span onClick={() => SignInController.showSignInDialog()}>
                        {t('MainDashboardHeader.login')} <i className='fa fa-chevron-down'></i>
                    </span>
                )}
            </div>
        );
    };

    return (
        <div className='StreamTest'>
            {renderUserActions()}
            <BoxCoverPlayerDetailsComponent movieId='161057' omitAds omitPreviewsCount />
            <ModalContainer />
            <Notifications />
        </div>
    )
};

StreamTest.displayName = 'StreamTest';

export default StreamTest;