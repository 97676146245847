import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { navigate } from "@reach/router";

import { env } from '../../env';

// https://github.com/cure53/DOMPurify

function RenderHtmlContent({ htmlContent = {}, dataLabel }) {
  const [state, setState] = useState('');

  useEffect(() => {
    if (!htmlContent || (typeof htmlContent !== 'object' && Object.keys(htmlContent).length === 0)) return;

    let data = htmlContent[dataLabel]?.content || '';

    // create style from string content and append it to <head>, DOMPurify will remove it later
    createCSS(data, dataLabel);

    // replace variables from Gandolf
    const htmlText = replaceVariables(data.trim());
    setState(htmlText);

    const intervalID = setInterval(() => {
      const links = document.querySelectorAll('.JS_internal_link');
      // internal links from Gandolf to prevent full page reload, className is added in DOMPurify hook
      if (links) {
        clearInterval(intervalID);
        links.forEach(link => {
          link.addEventListener('click', ev => {
            try {
              const url = ev.target.getAttribute('href');
              if (url.startsWith('/')) {
                ev.preventDefault();
                navigate(url);
              }
            } catch (err) {
              console.log(err);
            }
          })
        });
      }
    }, 1000);

    return () => {
      // remove created styles
      const gandolfSection = document.querySelectorAll(`[class*=${dataLabel}-gandolf-section]`);
      Array.from(gandolfSection).forEach(tag => tag.remove());
    }
  }, [htmlContent, dataLabel]);

  const renderView = () => {
    // add a className to internal links
    DOMPurify.addHook('afterSanitizeAttributes', node => {
      if (node.tagName && node.tagName.toLowerCase() === 'a') {
        const url = node.getAttribute('href');
        if (url.startsWith('/')) {
          node.classList.add('JS_internal_link');
        }
        // prevent XSS attack
        if (url.includes('javascript') || !url) {
          node.setAttribute('href', '#!');
        }
      }
    });

    const sanitizedHtmlContent = DOMPurify.sanitize(state);

    if (!sanitizedHtmlContent) return null;

    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }} />
  };

  return renderView();
}

export default RenderHtmlContent;

const createCSS = (data, dataLabel) => {
  const hasStyle = data.indexOf('<style>');

  if (hasStyle >= 0) {
    // extract just a text between <style></style>
    const cssContent = data.slice(data.indexOf('<style>') + 7, data.indexOf('</style>'));
    const tag = document.createElement('style');
    tag.setAttribute('class', `${dataLabel}-gandolf-section`);
    tag.textContent = cssContent;
    document.head.appendChild(tag);
  }
};

const replaceVariables = (text) => {
  if (!text) return null;
  if (!env && typeof env !== 'object') return text;

  // all variables from gandolf as array -> ['${REACT_APP_SITE_NAME}', '${REACT_APP_SITE_TITLE}', etc...]
  const gVariables = text.match(/\${(.*?)\}/g);

  if (!gVariables) return text;

  let updatedText = text;

  Object.entries(env).forEach(eVar => {
    gVariables.forEach(gVar => {
      const gVarTrimed = gVar.replace('${', '').replace('}', '');
      if (eVar[0] === gVarTrimed) {
        updatedText = updatedText.replaceAll(gVar, eVar[1]); // ${REACT_APP_SITE_NAME} -> NakedSword.com
      }
    })
  });

  return updatedText;
};