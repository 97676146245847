import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import { FullPlayer, VideoPlayController } from "@falconstudios/ns-player";

import { getImageByWidth } from '../../services/images-service/images.service';
import { showEntityDetailsPage } from '../../services/navigation/navigation.service';
import { isWeb } from '../../services/util-service/util.service';
import { DashboardController } from '../../controllers/dashboard-controller/DashboardController';
import MovieOverlay from '../MovieOverlay/MovieOverlay';
import ImageLoadingSkeleton from '../ImageLoadingSkeleton/ImageLoadingSkeleton';
import ImageLoaderComponent from '../ImageLoaderComponent/ImageLoaderComponent';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SlickSlider.scss';

const SlickSlider = (props) => {
    const {
        slides = {},
        onClick,
        renderPreview,
        showDots,
        bannerWidth,
        hideArrows,
        hideLoadingSkeleton,
        className
    } = props;
    const { data = [], info = {}, loading } = slides;
    const { open_in_new_tab = '_self', slider_delay = 5 } = info || {};

    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const onBannerClick = (url) => {
        if (!url) return;

        if (onClick) {
            onClick(url);
        } else {
            showEntityDetailsPage(url, open_in_new_tab ? '_blank' : '_self');
        }
    };

    const onBeforeChange = (newIndex) => {
        // restart autoPlay if is image
        if (!data[newIndex]?.videoClipUrl) {
            sliderRef.current.slickPlay();
        }
        setCurrentSlide(newIndex);
    };

    const restartSlider = () => {
        sliderRef.current.slickNext(); // move to next slide
        sliderRef.current.slickPlay(); // start autoPlay
    };

    const onPlayerSetupFinished = (playerId) => {
        const { player } = VideoPlayController.getVideoPlayer(playerId);

        if (player) {
            player.on('play', () => {
                // pause autoplay until video ends
                sliderRef.current.slickPause();
            });
            player.on('ended', () => restartSlider());
            player.on('error', (error) => {
                console.log(error);
                restartSlider();
            });
        }
    };

    const renderPreviewFn = ({ overlayTitle, overlaySubtitle, overlayText }) => {
        if (!renderPreview || !isWeb()) return undefined;

        return (
            <MovieOverlay
                bottomText={overlayText}
                middleText={overlaySubtitle}
                topText={overlayTitle}
            />
        );
    };

    const renderPlayer = (item) => {
        const {
            overlay_title: overlayTitle,
            overlay_text: overlayText,
            overlay_subtitle: overlaySubtitle,
            videoClipUrl,
            url
        } = item;

        const data = {
            playOnLoopWhileMouseOver: true,
            autoplay: true,
            inlinePlayer: true,
            isPreview: true,
            renderPreview: () => renderPreviewFn({ overlayTitle, overlaySubtitle, overlayText }),
            onPreviewClick: onBannerClick.bind(this, url),
            progressBarColors: DashboardController.getPlayerConfig().progressBarColors,
            videos: [{
                sources: [{ src: videoClipUrl }],
            }]
        };

        return (
            <FullPlayer
                onPlayerSetupFinished={onPlayerSetupFinished}
                key={item.id}
                data={data}
            />
        )
    };

    const renderImage = (image = []) => {
        if (image.length === 0) return null;

        const { url = '', width, height, alt } = getImageByWidth(image, bannerWidth) || {};

        if (!url) return null;

        return (
            <ImageLoaderComponent
                url={url}
                alt={alt || 'Banner Image'}
                width={width}
                height={height}
            />
        )
    };

    const settings = {
        dots: showDots || isWeb() ? true : false,
        arrows: hideArrows || isWeb() ? false : true,
        autoplay: true,
        fade: true,
        speed: 600,
        autoplaySpeed: slider_delay * 1000 // set in seconds from Gandolf
    };

    const renderView = () => {
        if (loading && !hideLoadingSkeleton) return <ImageLoadingSkeleton className='SlidePlaceholder' />;

        if (!loading && !data[0]) return null;

        if (!Array.isArray(data)) return null;

        return (
            <Slider
                {...settings}
                beforeChange={(oldIndex, newIndex) => onBeforeChange(newIndex)}
                ref={sliderRef}
            >
                {data
                    .map((obj = {}) => {
                        if (obj.videoClipUrl) { // if has videoClip image is not needed
                            obj.image = []; // on slide change image is flashing, so remove all images
                        }
                        return obj;
                    })
                    .map((item, index) => {
                        const { id, image = [], videoClipUrl } = item || {};
                        let view = renderImage(image);

                        // currentSlide === index -> render Player only if is current slide
                        if (videoClipUrl && (currentSlide === index)) view = renderPlayer(item);

                        return (
                            <div
                                key={id}
                                className='SliderContent'
                                title={data[currentSlide]?.title}
                                data-info={`BannersSet-${info.id} (${info.name}) Banner-${id}`}
                                onClick={() => onBannerClick(data[currentSlide]?.url)}
                            >
                                {view}
                            </div>
                        )
                    })}
            </Slider>
        )
    };

    const classNames = ['SlickSliderContainer'];

    if (className) {
        classNames.push(className);
    }

    if (!loading && !data[0]) {
        classNames.push('Hidden'); // for parent components css
    }

    return <div className={classNames.join(' ')}>{renderView()}</div>;
};

SlickSlider.displayName = 'SlickSlider';

SlickSlider.propTypes = {
    slides: PropTypes.object,
    onClick: PropTypes.func,
    renderPreview: PropTypes.bool,
    showDots: PropTypes.bool,
    bannerWidth: PropTypes.number,
    hideArrows: PropTypes.bool,
    hideLoadingSkeleton: PropTypes.bool,
    className: PropTypes.string
};

export default SlickSlider;