import React from 'react';
import { Link } from "@reach/router";

import { ROUTES } from "../../../services/navigation/navigation.routes";
import { useGtag } from '../../../hook/useGtag.js';
import { useHtmlContentContext } from '../../../context/HtmlContentContext.js';
import RenderHtmlContent from '../../../components/RenderHtmlContent/RenderHtmlContent.js';

const AvsCancel = () => {
  const { htmlContent } = useHtmlContentContext();

  useGtag({
    data: {
      event: 'page_view',
      page_title: 'AVS Cancel',
      page_location: window.location.href,
      page_path: window.location.pathname,
      event_label: 'cancel_page_loaded',
      event_category: 'age_verification',
      verification_stage: 'cancel_page_loaded'
    }
  });

  return (
    <>
      <div className="Content">
        <div className="Heading">AGE VERIFICATION CANCEL</div>

        <p>If you canceled by accident. Please <Link className="Link" to={`/${ROUTES.ageVerificationLanding}`}>click here to try again</Link>.
        </p>

      </div>
      <RenderHtmlContent htmlContent={htmlContent} dataLabel='support_service' />
    </>
  );
};

AvsCancel.displayName = 'AvsCancel';

export default AvsCancel;